.main-screen {
  display: flex;
  width: 100%;
  height: 100svh;
  overflow: hidden;
  background-color: #FFFFFF;


  .nav-mobile {
    display: none;
  }

  &-list-chats {
    width: 15%;
    height: 100%;
    background: #121212;
    padding: 2rem 15px 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .nav-container {
      width: 100%;
      overflow-y: scroll;
      height: 100%;
    }

    & .list-chats-title {
      color:  #FFF;
      font-family: "GT America Trial", sans-serif;
      font-size: 40px;
      font-style: normal;
      font-weight: 300;
      line-height: 100%; /* 40px */
      letter-spacing: 3px;
      text-transform: uppercase;
      margin-bottom:35px ;
    }


    &-item {
      margin: 20px 0;
      display: flex;
      align-items: center;
      color: #FFF;
      font-family: 'Helvetica', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      & div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      & .icon-chat {
        margin-right: 8px;
      }
    }

    &-item-selected {
      margin: 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #FFF;
      font-family: 'Helvetica', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      & div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      & .icon-chat {
        margin-right: 8px ;
      }
      & .delete-chat-icon {
        margin-right: 4px;
      }
    }

    &-buttonAdd {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 9px 21px;
      font-style: normal;
      font-weight: 400;
      border-radius: 17px;
      background: #D4FA75;
      color: var(--Black, #121212);
      text-align: center;
      font-family: 'Helvetica', sans-serif;
      font-size: 12px;
      line-height: 140%; /* 16.8px */
      text-transform: uppercase;
      margin: 0 auto 10%;
    }
    &-buttonAddDisabled {
      cursor: default;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 9px 21px;
      font-style: normal;
      font-weight: 400;
      border-radius: 17px;
      background: #ddf3a5;
      color: var(--Black, #121212);
      text-align: center;
      font-family: 'Helvetica', sans-serif;
      font-size: 12px;
      line-height: 140%; /* 16.8px */
      text-transform: uppercase;
      margin: 0 auto 10%;
    }
  }
  & .chat-container {
    height: 100%;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow-y: hidden;
    scroll-padding-bottom: 0;

    &-chat {
      width: 55%;
      overflow-y: clip;
      height: 100%;
      &-item {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        & .container {
          height: fit-content;
          overflow-y: auto;
          overflow-x: hidden;
          margin: 0;
          padding: 0;
        }
      }
    }
    &-input {
      display: flex;
      position: relative !important;
      width: 60%;
      & div {
        width: 100%;
      }
      textarea {
        width: 100%;
        border: 1px solid #7B67F2;
        background: #FFF;
        height: auto;
        padding: 15px 40px  15px 20px;
        font-style: normal;
        font-weight: 400;
        border-radius: 24px;
        font-family: 'Helvetica', sans-serif;
        font-size: 16px;
        line-height: normal;
        resize: none;
        &::placeholder {
          color: #9691B2;
        }
        &:focus {
          border: 1px solid #7B67F2;
          color: #1B1B1B ;
        }
        &:focus-visible {
          border: 1px solid #7B67F2;
          color: #1B1B1B ;
          outline: none;
        }
      }

      .icon {
        position: absolute;
        right: 13px;
        bottom: 18px;
        width: 25px;
        height: 25px;
      }
    }
  }

  .navbar-toggler-icon {
    width: 1.5em;
    height: 1.5em;
  }
}

.privacy-terms {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  & a {
    color: #858585;
    font-family: 'Helvetica', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    text-shadow: none;
  }
}

@media (max-width: 1115px) {
  .main-screen-list-chats .list-chats-title {
    font-size: 35px;
    margin-bottom:25px ;
  }
}

@media (max-width: 991px) {
  .main-screen {
    display: flex;
    flex-direction: column;
  }

  .main-screen .chat-container {
    width: 100%;
    //min-height: 90vh;
    //max-height: 90vh;
  }

  .main-screen .chat-container-chat {
    width: 70%;
  }

  .main-screen .chat-container-input {
    width: 77%;
  }

    .nav-mobile {
    display: block !important;
    background: #7B67F2;
    padding: 1rem;
  }

  .privacy-terms a{
   font-size: 12px;
  }

  .navbar {
    padding: 0;
    background-color: #7B67F2 !important;

    & .container-fluid {
      background: transparent;
    }
  }
  .main-screen-list-chats {
    display: none;
  }

  .main-screen-chat {
    padding: 0 2rem 2rem;
    width: 100%;
  }

  .offcanvas {
    background-color: #121212;
    width: 350px !important;
  }

  .main-screen-list-chats-item {
      margin: 20px 0;
      display: flex;
      align-items: center;
      color: #FFF;
      font-family: 'Helvetica', sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      & img {
        margin-right: 5px ;
        width: 20px;
        height: 20px;
      }

  }
  .main-screen-list-chats-item-selected {
    margin: 20px 0;
    display: flex;
    align-items: center;
    color: #FFF;
    font-family: 'Helvetica', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    & img {
      margin-right: 5px;
      width: 20px;
      height: 20px;
    }

    & .delete-chat-icon {
      margin-right: 1rem;
    }
  }

  .main-screen-list-chats-buttonAdd {
    font-size: 15px;
  }

  .main-screen-list-chats-buttonAddDisabled {
    font-size: 15px;
  }

  .navbar-nav {
    height: 100%;
    justify-content: space-between;
  }

  .loader {
    left: 52% !important;
  }
}
.navbar-toggler:focus {
  box-shadow: none;
}
.offcanvas-title {
  color:  #FFF;
  font-family: "GT America Trial", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 40px */
  letter-spacing: -2px;
  text-transform: uppercase;
}
.offcanvas-body {
  padding: 0 var(--bs-offcanvas-padding-x) 2rem;
  overflow-y: hidden;
  & .nav-container {
    overflow-y: auto;

  }
}
@media (max-width: 768px) {

  .main-screen { padding: 0;
    &-chat {
      width: 100%;
    }
    &-input {
      margin: 1rem 0;
      width: 100%;
    }}
  .main-screen-chat-input  {
    margin-bottom:2rem ;
  }
  .loader {
    left: 54% !important;
  }
  .item-message-user {
    margin: 12px 0 !important;
  }
  .item-message-chat {
    margin: 12px 0 !important;
  }
}

@media (max-width: 540px) {

  .main-screen .chat-container {
    //max-height: 85vh;
    //min-height: 85vh;
  }

  .loader {
    left: 57% !important;
  }
  .main-screen .chat-container-chat {
    width: 86%;
  }

  .main-screen .chat-container-input {
    width: 96%;
  }
}
 .user-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & p {
    color: #FFF;
    font-family: 'Helvetica', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-align: center;
  }
  & span {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 17px;
    background: #7B67F2;
    min-width: 52px;
    min-height: 35px;
    color:  #121212;
    text-align: center;
    font-family: 'Helvetica', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 28px */
    text-transform: uppercase;
  }
   & img {
     width: 25px;
     height: 25px;
   }
}


@media (min-width: 1921px) {
  .main-screen-list-chats .list-chats-title {
      font-size: 65px;
  }
  .main-screen-list-chats-item-selected {
    font-size: 25px;
  }

  .main-screen-list-chats-item {
    font-size: 25px;
  }
  .main-screen-list-chats-buttonAdd {
    font-size: 15px;
  }
  .user-menu {
    & span {
      font-size: 26px;
    }
    & p {
      font-size: 20px;
    }
    & img {
      width: 30px;
    }
  }
  .item-message-chat p {
    font-size: 20px !important;
  }
  .item-message-user p {
    font-size: 20px !important;
  }
  .main-screen .chat-container-input input  {
    font-size: 20px !important;
  }
  .main-screen .chat-container-input .icon {
    width: 25px;
    height: 25px;
  }
}


