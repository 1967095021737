
.CreateChat .modal-dialog .modal-body .input-item{
  margin: 1rem 0 0;
}

.CreateChat .modal-dialog .modal-content {
  padding: 2rem 1rem;
}
.modal-dialog {
  & .modal-content {
    padding: 2rem 3.5rem;
    border-radius: 100px;
    background: #FFF;
  }

  .btn-close {
    display: none;
  }

  & .modal-header {
    align-items: center;
    justify-content: center;
    border: none;

    .modal-title {
      color: #000;
      font-family: "GT America Trial", sans-serif;
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -1.8px;
      text-transform: uppercase;
    }
  }

  & .modal-body {
    padding: 0;
    border: none;

    & .input-item {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin: 1rem 0;

      & label {
        color: #121212;
        font-family: 'Helvetica', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 28px */
        text-transform: uppercase;
      }

      & input {
        color: #121212;
        font-family: 'Helvetica', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        border-bottom: 1px solid #121212;
        padding-bottom: 5px;
      }
    }
  }

  & .modal-footer {
    border: none;
    padding: var(--bs-modal-header-padding) 0  ;
    justify-content: center;
    display: flex;
    align-items: center;

    .button-submit {
      border-radius: 17px;
      background: #D4FA75;
      color: #121212;
      text-align: center;
      font-family: 'Helvetica', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      text-transform: uppercase;
      width: fit-content;
      border: none;
      padding: 9px 24px;
    }


  }
  @media (max-width: 500px) {
    & .modal-content {
      padding: 1rem 2rem;
    }
    & .modal-title.h4 {
      font-size: 30px;
    }
  }


  @media (min-width: 1921px) {
      .modal-header .modal-title {
      font-size: 40px ;
    }
    .modal-body .input-item {
      & label {
        font-size: 25px ;
      }
      & input {
        font-size: 18px;
      }
    }
   .modal-footer .button-submit {
     font-size: 16px;
   }
  }
}
