.LoadingMessage {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 46px;
  left: 32px;
  & .rotating-svg {
    display: flex;
    animation: rotate 2s linear infinite;
  }
}


@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@media (max-width: 768px) {
  .LoadingMessage{
    width: 33px;
    height: 33px;
    top: 48px;
    left: 32px;
  }
}
