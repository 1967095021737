.login-screen {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding:  3rem 4rem 1rem;;
  background: #121212;
  min-height: 100svh;
  overflow-y: auto;
  justify-content: space-between;

  .logo {
    width: 105px;
    height: 17px;
    margin-bottom: 3rem;
  }

  &-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    &-top {
      display: flex;
      width: 100%;
      justify-content: space-between;
      h1 {
        width: 48%;
        color: #E3DEEB;
        font-family: "GT-America-Extended-Regular-Trial", sans-serif;
        font-size: 66px;
        font-style: normal;
        font-weight: 400;
        text-transform: uppercase;
        max-width: 650px;
        margin: 0;
        line-height: 100%;
        letter-spacing: -3.3px;
      }

      img {
        width: 48%;
        border-radius: 628px;
        max-height: 490px;
      }
    }

    &-main {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: flex-end;
      & p {
        color: #FFF;
        font-family: 'Helvetica', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 160%;
        max-width: 500px;
      }

      & .button-login {
        font-family: 'Helvetica', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        text-transform: uppercase;
        padding: 20px 25px;
        border-radius: 96px;
        background:  #7B67F2;
        max-height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #FFFFFF;
        & img {
          margin-left: 1rem;
        }
      }
    }
  }

  .footer {
    margin: 2.5rem auto 0;

    & .social-links {
      display: flex;
      justify-content: space-evenly;
      & a img{
        width: 23px;
        height: 23px;
      }
    }
    & p {
      margin: 2% auto 0;
      color: rgba(227, 222, 235, 0.54);
      text-align: center;
      font-family: 'Helvetica', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 23.4px */
      text-transform: uppercase;
    }
  }

  }
  @media (max-width: 1030px) {
    .login-screen {
      padding:  2rem 2rem 1rem;
      overflow-y: auto;
      &-container {
        &-top {
          flex-direction: column;
          justify-content: center;
          & h1 {
            width: 100%;
            font-size: 45px;
            max-width: 900px;
          }
          & img {
            width: 90%;
            margin: 1.5rem auto 0 ;
          }
        }
        &-main {
          flex-direction: column;
          & p {
            max-width: 100%;
            line-height: 120%;
            font-size: 16px;
            margin: 1.5rem 0;
          }
          .button-login {
            max-width: 350px;
            margin: 0 auto;
            width: 100%;
            justify-content: space-evenly;
            & img  {
              margin: 0;
            }
          }
        }
      }
    }
    .login-screen .logo {
      margin-bottom: 3rem;
    }

    .login-screen .footer {
      margin:  3rem auto 0;

      & .social-links {
        & a img{
          width: 30px;
          height: 30px;
        }
      }
      & p {
        font-size: 13px;
      }
    }
  }


@media (max-height: 700px) {
  .login-screen-top img {
    margin: 1rem  auto 0;
    min-width: 230px;
    min-height: 170px;
  }
  .login-screen-top h1 {
    font-size: 35px;
    line-height: 100%;
  }
  .login-screen-main p {
    margin: 1rem 0;
    font-size: 14px;
  }
}

@media (min-width: 1921px) {
  .login-screen {
    padding:  3rem 4rem 1rem;
    & h1 {
      max-width: 850px;
      font-size: 90px;
    }
    & img  {
      max-width: 840px;
      max-height: 600px;
    }
    & .login-screen-main  {
     &  p {
        max-width: 750px;
        font-size: 25px;
      }
     & .button-login {
       font-size: 25px;
       padding: 25px 35px;
      }
    }
  }
}

@media (min-width: 1440px) and  (max-width: 1920px){
  .login-screen {
     padding:  3rem 4rem 1rem;;
    & h1 {
      max-width: 650px;
      font-size: 66px;
    }
    & img  {
      max-width: 670px;
      max-height: 570px;
    }
    & .login-screen-main  {
      &  p {
        max-width: 500px;
        font-size: 18px;
      }
    }
  }
}
