.item-message {
  display: flex;
  &-user {
    background: #D4FA75;
    border-radius: 20px;
    width: fit-content;
    max-width: 93%;
    border-top-right-radius: 0 ;
    margin: 25px 0;
    padding: 1rem 1.4rem;
    color: #2E2E2E;
    overflow-x: auto;
    & p {
      font-family: "Helvetica", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      overflow-x: auto;
    }
  }

  &-chat {
    background: #7B67F2;
    border-radius: 20px;
    width: fit-content;
    max-width: 93%;
    border-top-left-radius: 0 ;
    margin: 25px 0;
    padding: 1rem 1.4rem;
    color: #FFFFFF;
    position: relative;
    min-height: 64px;
    min-width: 64px;
    & p {
      font-family: "Helvetica", sans-serif;
      font-style: normal;
      font-weight: 400;
      color: #FFF;
      font-size: 16px;
      line-height: 22px;
    }
  }

  @media (max-width: 500px) {
    .item-message-chat p {
      font-size: 18px;
    }
    .item-message-user p {
      font-size: 18px;
    }

  }
}
