@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url("https://fonts.cdnfonts.com/css/gt-america-trial");
@import url("https://fonts.cdnfonts.com/css/helvetica-neue-9");
@import '~bootstrap/scss/bootstrap';

/* Reset and base styles  */

@font-face {
    font-family: 'GT-America-Extended-Regular-Trial';
    src: url('../assets/fonts/GT-America-Extended-Regular-Trial.woff2') format('truetype');
}

@font-face {
    font-family: 'GT-America-Extended-Bold-Trial';
    src: url('../assets/fonts/GT-America-Extended-Bold-Trial.woff2') format('truetype');
}



* {
    padding: 0px;
    margin: 0px;
    border: none;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Links */

a, a:link, a:visited  {
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
}

a:hover  {
    text-decoration: none;
}

/* Common */

aside, nav, footer, header, section, main {
    display: block;
}

h1, h2, h3, h4, h5, h6, p {
    font-size: inherit;
    font-weight: inherit;
}

ul, ul li {
    list-style: none;
}

img {
    vertical-align: top;
}

img, svg {
    max-width: 100%;
    height: auto;
}

address {
    font-style: normal;
}

/* Form */

input, textarea, button, select {
    font-family: 'Roboto', sans-serif;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
}



button, input[type="submit"] {
    display: inline-block;
    box-shadow: none;
    background-color: transparent;
    background: none;
    cursor: pointer;
}

input:focus, input:active,
button:focus, button:active {
    outline: none;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

label {
    cursor: pointer;
}

legend {
    display: block;
}

p {
    font-family:'Roboto', "GT America Trial", "Helvetica", sans-serif;
    margin: 0;
}

#root {
    overflow: hidden;
}


html, body {
    background: #121212;
    margin-bottom: env(safe-area-inset-bottom);
}

::-webkit-scrollbar {
    width: 0;
    background: transparent;
}
.input {
    & p {
        color: #1B1B1B;
        font-weight: 400;
        font-family: "GT America Trial", sans-serif;
        font-size: 24px;
        font-style: normal;
        line-height: 100%;
        text-transform: uppercase;
        margin-bottom: 0.5rem;
    }
    & input {
        width: 100%;
        border-radius: 20px;
        border: 2px solid #868686;
        background: #FFF;
        padding: 14px 20px;
        min-height: 46px;
        color: #121212;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        &:focus {
            border: 2px solid #7B67F2;
            color: #1B1B1B ;
        }
        &:focus-visible {
            border: 2px solid #7B67F2;
            color: #1B1B1B ;
        }
    }
}

@media (max-height: 700px) {
    html, body {
        overflow: auto;
    }
}
