.ModalDeleteChat  {
  & .modal-dialog {
    justify-content: center;
    & .modal-content {
      padding: 1rem 1rem;
      border-radius: 50px;
      border: none ;
      width: 60%;
  }
    & .modal-header .modal-title {
      color: #000;
      font-family: "GT-America-Extended-Regular-Trial", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -0.8px;
      text-transform: uppercase;
    }

    &  .modal-body {
      margin: 0 auto;
      & .text {
        font-family: "GT-America-Extended-Regular-Trial", sans-serif;
        font-style: normal;
        font-weight: 400;
        color: #000;
        font-size: 16px;
        line-height: 100%; /* 16px */
        letter-spacing: -0.8px;
        & span {
          font-family: " GT-America-Extended-Bold-Trial", sans-serif;
          font-weight: 800;
        }
      }
    }
    & .modal-footer {
      justify-content: space-around;
      & .delete {
        background-color: #FA6D4E;
        color: #FFFFFF;
      }
      & .cancel  {
        background-color: transparent;
        border: 1px solid #000000;
      }
    }
  }

}
@media (max-width: 500px) {
  .ModalDeleteChat .modal-dialog .modal-content {
    width: 80%;
  }
}
